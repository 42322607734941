// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Oe7pcLs73", "jEzs_9ffj"];

const serializationHash = "framer-EMmu1"

const variantClassNames = {jEzs_9ffj: "framer-v-gu4gr0", Oe7pcLs73: "framer-v-1t803e0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "Oe7pcLs73", "Variant 2": "jEzs_9ffj"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Oe7pcLs73"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Oe7pcLs73", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1k6myza = activeVariantCallback(async (...args) => {
await delay(() => setVariant("jEzs_9ffj"), 1000)
})

const onAppearn726nm = activeVariantCallback(async (...args) => {
await delay(() => setVariant("Oe7pcLs73"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear1k6myza, jEzs_9ffj: onAppearn726nm})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1t803e0", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"Oe7pcLs73"} ref={ref ?? ref1} style={{backgroundColor: "rgb(44, 8, 128)", borderBottomRightRadius: 250, borderTopLeftRadius: 250, boxShadow: "0px 0px 3px 3px rgb(255, 175, 46)", ...style}} variants={{jEzs_9ffj: {backgroundColor: "rgb(255, 175, 46)", boxShadow: "0px 0px 3px 3px rgb(44, 8, 128)"}}} {...addPropertyOverrides({jEzs_9ffj: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QWxhdHNpLXJlZ3VsYXI=", "--framer-font-family": "\"Alatsi\", \"Alatsi Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Tax &amp; Other Compliance</motion.p></React.Fragment>} className={"framer-85j10r"} fonts={["GF;Alatsi-regular"]} layoutDependency={layoutDependency} layoutId={"jtSl49cNI"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} variants={{jEzs_9ffj: {"--extracted-r6o4lv": "rgb(44, 8, 128)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({jEzs_9ffj: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7QWxhdHNpLXJlZ3VsYXI=", "--framer-font-family": "\"Alatsi\", \"Alatsi Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(44, 8, 128))"}}>Tax &amp; Other Compliance</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EMmu1.framer-1hvbawf, .framer-EMmu1 .framer-1hvbawf { display: block; }", ".framer-EMmu1.framer-1t803e0 { height: 36px; position: relative; width: 244px; }", ".framer-EMmu1 .framer-85j10r { flex: none; height: auto; left: 52%; position: absolute; top: 47%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 244
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jEzs_9ffj":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbRcTCMkHL: React.ComponentType<Props> = withCSS(Component, css, "framer-EMmu1") as typeof Component;
export default FramerbRcTCMkHL;

FramerbRcTCMkHL.displayName = "Mobile tax service";

FramerbRcTCMkHL.defaultProps = {height: 36, width: 244};

addPropertyControls(FramerbRcTCMkHL, {variant: {options: ["Oe7pcLs73", "jEzs_9ffj"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerbRcTCMkHL, [{explicitInter: true, fonts: [{family: "Alatsi", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/alatsi/v13/TK3iWkUJAxQ2nLNGGzUHte5fKg.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})